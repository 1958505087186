import {FunctionComponent, useContext} from "react"
import {useLocation, useNavigate} from "react-router-dom"
import {Button, Menu} from "antd"
import {GREEN} from "../../constants/colors"
import {IconClose} from "../icons"
import {AuthContext, checkRoutePermissions} from "../../context/AuthContext"

const ROUTES = [
  {
    key: "users",
    label: "Сотрудники"
  },
  {
    key: "orders",
    label: "Заказы"
  },
  {
    key: "suppliers",
    label: "Поставщики"
  },
  {
    key: "customers",
    label: "Клиенты"
  },
  {
    key: "catalog",
    label: "Каталог"
  },
  {
    key: "products_to_order",
    label: "Товары для заказа"
  },
  {
    key: "supplier_orders",
    label: "Заказы поставщика"
  },
  {
    key: "templates",
    label: "Шаблоны"
  }
]

const MainMenu: FunctionComponent = () => {
  const {pathname} = useLocation()
  const navigate = useNavigate()
  const {userInfo, logout} = useContext(AuthContext)

  const closeMenu = (): void => {
    document.getElementById("menu")?.classList.remove("opened")
  }

  return (
    <div className="menu" id="menu">
      <div className="menu__close" onClick={closeMenu}>
        <IconClose stroke={GREEN} size={40} />
      </div>
      <Menu
        selectedKeys={[pathname.split("/")[1]]}
        onClick={({key}): void => {
          navigate(`/${key}`)
          closeMenu()
        }}
        theme="dark"
        items={ROUTES.filter(route => checkRoutePermissions(userInfo!.role, route.key))}
      />
      <div className="menu__email">
        {userInfo?.email}
        <Button className="menu__logout-button" type="default" ghost onClick={logout}>
          Выйти
        </Button>
      </div>
    </div>
  )
}

export default MainMenu
