import {ApolloClient, createHttpLink, InMemoryCache} from "@apollo/client"
import {setContext} from "@apollo/client/link/context"

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_HASURA_GRAPHQL_URI
})

const authLink = setContext((_, {headers}) => {
  const token = localStorage.getItem("m_token")
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : ""
    }
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
})

export default client
