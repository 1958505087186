/* eslint-disable @typescript-eslint/no-unused-vars */
export const GREEN = "#26BC78"
export const YELLOWGREEN = "#AACE19"
export const LIGHT_BLUE = "#22CCE2"
export const BLUE = "#1C7CD5"
export const PURPLE = "#D44AA6"
export const ORANGE = "#E08309"
export const MAIN_1 = "#0E354A"
export const MAIN_2 = "#133A4F"
export const MAIN_3 = "#274B5E"
export const MAIN_4 = "#587583"
export const MAIN_5 = "#90A6B2"
export const MAIN_6 = "#F0F4F5"
export const GREY = "#7A7A7A"
