import React, {FunctionComponent} from "react"
import {FrownOutlined} from "@ant-design/icons"

const ErrorPlaceholder: FunctionComponent = () => (
  <div className="error-placeholder">
    <FrownOutlined style={{fontSize: 60}} />
    <p className="error-placeholder__message">Упс, что-то пошло не так</p>
  </div>
)

export default ErrorPlaceholder
