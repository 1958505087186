import {Component, ErrorInfo, lazy, Suspense} from "react"
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom"
import {ApolloProvider} from "@apollo/client"
import {AuthContextProvider, ProtectedZone} from "./context/AuthContext"
import Header from "./components/Header/Header"
import client from "./client"
import Loader from "./components/Loader/Loader"
import ErrorPlaceholder from "./components/ErrorPlaceholder/ErrorPlaceholder"

const Orders = lazy(() => import("./pages/Orders/Orders"))
const Login = lazy(() => import("./pages/Login/Login"))
const Suppliers = lazy(() => import("./pages/Suppliers"))
const Customers = lazy(() => import("./pages/Customers/Customers"))
const Catalog = lazy(() => import("./pages/Catalog/Catalog"))
const AddEditOrder = lazy(() => import("./pages/AddEditOrder/AddEditOrder"))
const EditOrder = lazy(() => import("./pages/AddEditOrder/EditOrder"))
const ProductsToOrder = lazy(() => import("./pages/ProductsToOrder/ProductsToOrder"))
const SupplierOrders = lazy(() => import("./pages/SupplierOrders/SupplierOrders"))
const SupplierOrder = lazy(() => import("./pages/SupplierOrder"))
const ChangePassword = lazy(() => import("./pages/ChangePassword"))
const Users = lazy(() => import("./pages/Users"))
const Templates = lazy(() => import("./pages/Templates/Templates"))

export default class App extends Component<Record<string, never>, {error: boolean}> {
  state = {
    error: false
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error)
    console.error(errorInfo)
    this.setState({error: true})
  }

  render() {
    if (this.state.error) {
      return <ErrorPlaceholder />
    }

    return (
      <ApolloProvider client={client}>
        <AuthContextProvider>
          <BrowserRouter>
            <Header />
            <Routes>
              <Route element={<ProtectedZone />}>
                <Route
                  path="/users"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Users />
                    </Suspense>
                  }
                />
                <Route
                  path="/orders"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Orders />
                    </Suspense>
                  }
                />
                <Route
                  path="/orders/add"
                  element={
                    <Suspense fallback={<Loader />}>
                      <AddEditOrder edit={false} />
                    </Suspense>
                  }
                />
                <Route
                  path="/orders/edit/:id"
                  element={
                    <Suspense fallback={<Loader />}>
                      <EditOrder />
                    </Suspense>
                  }
                />
                <Route
                  path="/suppliers"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Suppliers />
                    </Suspense>
                  }
                />
                <Route
                  path="/customers"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Customers />
                    </Suspense>
                  }
                />
                <Route
                  path="/catalog"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Catalog />
                    </Suspense>
                  }
                />
                <Route
                  path="/products_to_order"
                  element={
                    <Suspense fallback={<Loader />}>
                      <ProductsToOrder />
                    </Suspense>
                  }
                />
                <Route
                  path="/supplier_orders"
                  element={
                    <Suspense fallback={<Loader />}>
                      <SupplierOrders />
                    </Suspense>
                  }
                />
                <Route
                  path="/supplier_orders/:id"
                  element={
                    <Suspense fallback={<Loader />}>
                      <SupplierOrder />
                    </Suspense>
                  }
                />
                <Route
                  path="/templates"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Templates />
                    </Suspense>
                  }
                />
                <Route
                  path="/change_password"
                  element={
                    <Suspense fallback={<Loader />}>
                      <ChangePassword />
                    </Suspense>
                  }
                />
                <Route path="/" element={<Navigate to="/orders" />} />
              </Route>
              <Route
                path="/login"
                element={
                  <Suspense fallback={<Loader />}>
                    <Login />
                  </Suspense>
                }
              />
            </Routes>
          </BrowserRouter>
        </AuthContextProvider>
      </ApolloProvider>
    )
  }
}
