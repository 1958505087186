import React, {FunctionComponent} from "react"
import {LoadingOutlined} from "@ant-design/icons"
import {Spin} from "antd"

const Loader: FunctionComponent<{overlay?: boolean}> = ({overlay}) => (
  <div className={`loader-wrapper ${overlay ? "loader-wrapper--overlay" : ""}`}>
    <Spin indicator={<LoadingOutlined style={{fontSize: 70}} spin />} />
  </div>
)
export default Loader
