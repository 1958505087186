import React, {FunctionComponent, Fragment, useContext} from "react"
import {Link} from "react-router-dom"
import {Button} from "antd"
import {IconMenu} from "../icons"
import {LIGHT_BLUE} from "../../constants/colors"
import {AuthContext} from "../../context/AuthContext"

const Header: FunctionComponent = () => {
  const {logout, userInfo} = useContext(AuthContext)

  const openMenu = (): void => {
    if (document.getElementById("menu")) {
      document.getElementById("menu")!.classList.add("opened")
    }
  }

  return (
    <div className={`header${userInfo ? "" : " header--login"}`}>
      {userInfo && (
        <div className="header__menu" onClick={openMenu}>
          <IconMenu fill={LIGHT_BLUE} size={30} />
        </div>
      )}
      {userInfo && <div className="header__logo" />}
      {userInfo && (
        <Fragment>
          <div className="header__email">{userInfo.email}</div>
          <Link to="/change_password">
            <Button className="header__logout-button" type="default" ghost>
              Сменить пароль
            </Button>
          </Link>
          <Button className="header__logout-button" type="default" ghost onClick={logout}>
            Выйти
          </Button>
        </Fragment>
      )}
    </div>
  )
}

export default Header
