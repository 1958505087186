import React, {FunctionComponent} from "react"

export const IconArrowDown: FunctionComponent<{fill: string}> = ({fill}) => (
  <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7071 1.70711C14.0976 1.31658 14.0976 0.683418 13.7071 0.292893C13.3166 -0.097631 12.6834
      -0.097631 12.2929 0.292893L7 5.58579L1.70711 0.292893C1.31658 -0.097631 0.683417 -0.097631
      0.292893 0.292893C-0.0976311 0.683418 -0.0976311 1.31658 0.292893 1.70711L6.29289 7.70711C6.68342
      8.09763 7.31658 8.09763 7.70711 7.70711L13.7071 1.70711Z"
      fill={fill}
    />
  </svg>
)

export const IconArrowUp: FunctionComponent<{fill: string}> = ({fill}) => (
  <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.292893 6.29289C-0.097631 6.68342 -0.097631 7.31658 0.292893 7.70711C0.683418 8.09763 1.31658
      8.09763 1.70711 7.70711L7 2.41421L12.2929 7.70711C12.6834 8.09763 13.3166 8.09763 13.7071
      7.70711C14.0976 7.31658 14.0976 6.68342 13.7071 6.29289L7.70711 0.292893C7.31658 -0.0976311
      6.68342 -0.0976311 6.29289 0.292893L0.292893 6.29289Z"
      fill={fill}
    />
  </svg>
)

export const IconDecorDots: FunctionComponent<{fill: string; size: number}> = ({fill, size}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 53 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="50.4766" y="50.4762" width="2.52381" height="2.52381" fill={fill} />
    <rect x="37.8574" y="50.4762" width="2.52381" height="2.52381" fill={fill} />
    <rect x="25.2383" y="50.4762" width="2.52381" height="2.52381" fill={fill} />
    <rect x="12.6191" y="50.4762" width="2.52381" height="2.52381" fill={fill} />
    <rect y="50.4762" width="2.52381" height="2.52381" fill={fill} />
    <rect x="50.4766" y="37.8571" width="2.52381" height="2.52381" fill={fill} />
    <rect x="37.8574" y="37.8571" width="2.52381" height="2.52381" fill={fill} />
    <rect x="25.2383" y="37.8571" width="2.52381" height="2.52381" fill={fill} />
    <rect x="12.6191" y="37.8571" width="2.52381" height="2.52381" fill={fill} />
    <rect y="37.8571" width="2.52381" height="2.52381" fill={fill} />
    <rect x="50.4766" y="25.2381" width="2.52381" height="2.52381" fill={fill} />
    <rect x="37.8574" y="25.2381" width="2.52381" height="2.52381" fill={fill} />
    <rect x="25.2383" y="25.2381" width="2.52381" height="2.52381" fill={fill} />
    <rect x="12.6191" y="25.2381" width="2.52381" height="2.52381" fill={fill} />
    <rect y="25.2381" width="2.52381" height="2.52381" fill={fill} />
    <rect x="50.4766" y="12.619" width="2.52381" height="2.52381" fill={fill} />
    <rect x="37.8574" y="12.619" width="2.52381" height="2.52381" fill={fill} />
    <rect x="25.2383" y="12.619" width="2.52381" height="2.52381" fill={fill} />
    <rect x="12.6191" y="12.619" width="2.52381" height="2.52381" fill={fill} />
    <rect y="12.619" width="2.52381" height="2.52381" fill={fill} />
    <rect x="50.4766" width="2.52381" height="2.52381" fill={fill} />
    <rect x="37.8574" width="2.52381" height="2.52381" fill={fill} />
    <rect x="25.2383" width="2.52381" height="2.52381" fill={fill} />
    <rect x="12.6191" width="2.52381" height="2.52381" fill={fill} />
    <rect width="2.52381" height="2.52381" fill={fill} />
  </svg>
)

export const IconClose: FunctionComponent<{stroke: string; size: number}> = ({stroke, size}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.92849 4.92893L11.9996 12M19.0706 19.0711L11.9996 12M11.9996 12L4.92849
      19.0711M11.9996 12L19.0706 4.92893"
      stroke={stroke}
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
)

export const IconMenu: FunctionComponent<{fill: string; size: number}> = ({fill, size}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 2H0V0H20V2ZM20 9H0V7H20V9ZM20 16H0V14H20V16Z"
      fill={fill}
    />
  </svg>
)
