import {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useContext,
  useEffect,
  useState
} from "react"
import decode from "jwt-decode"
import {Navigate, Outlet, useLocation} from "react-router-dom"
import {Role} from "../constants/roles"
import MainMenu from "../components/MainMenu/MainMenu"

type UserInfo = {
  email: string
  id: string
  role: keyof typeof Role
  password_change_required: boolean
}

type AuthContext = {
  userInfo: UserInfo | null
  isAdmin: boolean
  isAssembler: boolean
  isBuyer: boolean
  checkingToken: boolean
  login: (email: string, password: string) => Promise<void>
  logout: () => void
}

let timeout: NodeJS.Timeout | null = null

const useAuth = () => {
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null)
  const [checkingToken, setCheckingToken] = useState(true)

  const checkToken = (token: string) => {
    try {
      const {claims, exp} = decode<{
        claims: {
          "x-hasura-user-id": string
          "x-hasura-user-email": string
          "x-hasura-role": keyof typeof Role
          "x-hasura-password-change-required": string
        }
        exp: number
      }>(token)
      const timeToExp = exp * 1000 - new Date().getTime()
      if (timeToExp > 3000) {
        setUserInfo({
          id: claims["x-hasura-user-id"],
          email: claims["x-hasura-user-email"],
          role: claims["x-hasura-role"],
          password_change_required: claims["x-hasura-password-change-required"] === "true"
        })
        timeout = setTimeout(checkStoredToken, timeToExp)
      } else {
        setUserInfo(null)
      }
    } catch (e) {
      setUserInfo(null)
    }
  }

  const checkStoredToken = () => {
    setCheckingToken(true)
    const token = localStorage.getItem("m_token")
    if (token) {
      checkToken(token)
    }
    setCheckingToken(false)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(checkStoredToken, [])

  const login = async (email: string, password: string) => {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER_ADDRESS}login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({email, password})
    })
    if (!(res.status >= 200 && res.status < 300)) {
      throw new Error(res.status === 401 ? "Неверный логин или пароль" : "Произошла ошибка")
    }
    const token = await res.text()
    localStorage.setItem("m_token", token)
    checkToken(token)
  }

  const logout = () => {
    localStorage.removeItem("m_token")
    setUserInfo(null)
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
  }

  return {
    userInfo,
    isAdmin: userInfo?.role === "supervisor",
    isAssembler: userInfo?.role === "assembler",
    isBuyer: userInfo?.role === "buyer",
    checkingToken,
    login,
    logout
  }
}

export const AuthContext = createContext<AuthContext>({} as unknown as AuthContext)

export const AuthContextProvider: FunctionComponent<PropsWithChildren> = ({children}) => {
  const auth = useAuth()

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

const MANAGER_ROUTES = ["orders", "catalog", "customers", "change_password", "templates"]

const ASSEMBLER_ROUTES = ["orders", "change_password"]

const BUYER_ROUTES = [
  "orders",
  "suppliers",
  "customers",
  "products_to_order",
  "supplier_orders",
  "change_password"
]

export const checkRoutePermissions = (role: keyof typeof Role, route: string): boolean => {
  switch (role) {
    case "supervisor":
      return true
    case "manager":
      return MANAGER_ROUTES.includes(route)
    case "assembler":
      return ASSEMBLER_ROUTES.includes(route)
    case "buyer":
      return BUYER_ROUTES.includes(route)
    default:
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const exhaustiveCheck: never = role
      throw new Error("Unexpected role")
  }
}

export const ProtectedZone: FunctionComponent = () => {
  const {checkingToken, userInfo} = useContext(AuthContext)
  const {pathname} = useLocation()

  if (checkingToken) return null
  if (!userInfo) return <Navigate to="/login" />
  if (userInfo.password_change_required && pathname !== "/change_password") {
    return <Navigate to="/change_password" />
  }
  if (!checkRoutePermissions(userInfo.role, pathname.split("/")[1])) {
    return <Navigate to="/orders" />
  }
  return (
    <div className="dashboard-container">
      {!userInfo.password_change_required && <MainMenu />}
      <div className="content-container">
        <Outlet />
      </div>
    </div>
  )
}
